import React from 'react'

import Link from 'components/Link'
import Menu, { MenuItem, SubMenu } from 'components/UI/Menu'
import { helpHost, portalHost } from 'constants/env'

import style from './index.less'

export default function Overlay() {
  return (
    <Menu mode="inline" className={style.menu} rootMenus={['sub1', 'sub2', 'sub3', 'sub4', 'sub5']}>
      <MenuItem><Link href="/">首页</Link></MenuItem>
      <SubMenu title="软件产品">
        <MenuItem><Link href="/products/node">NiuLink 大节点</Link></MenuItem>
        <MenuItem><Link href="/products/lite">麻雀云小盒子</Link></MenuItem>
      </SubMenu>
      <MenuItem><Link href="/cooperations">商务合作</Link></MenuItem>
      <MenuItem><Link href={helpHost}>帮助中心</Link></MenuItem>
      <MenuItem><Link href={portalHost}>控制台</Link></MenuItem>
    </Menu>
  )
}
