/**
 * @file 全局的 Layout 组件
 * @description 每个页面的内容都应该用本组件包起来
 */

import React, { ReactNode, useEffect, PropsWithChildren } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { defaultTitle, titleSuffix } from 'constants/page'
import { pv } from 'utils/sensors'
import { OverlayProvider, OverlaySlot } from 'components/Overlay'

import ErrorBoundary from './ErrorBoundary'
import UaProvider from './UaProvider'
import Header from '../Header'
import Footer from '../Footer'
import PcPin from '../PcPin'

export type Props = {
  /** 页面 title */
  title: string
  /** 页面 keywords（SEO 用） */
  keywords: string
  /** 页面 description（SEO 用） */
  description: string
  children: ReactNode
  /** 自定义页面布局（不包含内置的 Header / Footer 等公共组件） */
  withCustomContent?: boolean
}

export default function Layout({ title, keywords, description, children, withCustomContent }: Props) {
  title = !title ? defaultTitle : (title + titleSuffix)

  usePv(title)

  const keywordsMeta = keywords && (
    <meta name="keywords" content={keywords} />
  )

  const descriptionMeta = description && (
    <meta name="description" content={description} />
  )

  const bodyView = withCustomContent ? children : <ContentWrapper>{children}</ContentWrapper>

  return (
    <UaProvider>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="initial-scale=1.0,width=device-width,user-scalable=no,viewport-fit=cover" />
        <link rel="shortcut icon" href="//www.niulinkcloud.com/favicon.ico" type="image/x-icon" />
        {keywordsMeta}
        {descriptionMeta}
      </Head>
      {bodyView}
    </UaProvider>
  )
}

function ContentWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <OverlayProvider>
      <Header />
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
      <Footer />
      <PcPin />
      <OverlaySlot />
    </OverlayProvider>
  )
}

function usePv(title: string) {
  const router = useRouter()
  const path = router.pathname
  useEffect(() => {
    pv(title, path)
  }, [title, path])
}
