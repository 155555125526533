import React, { PropsWithChildren, ReactElement, useRef, useState } from 'react'
import classnames from 'classnames'
import { Dropdown, Menu, MenuItem } from 'react-icecream-2'

import Link from 'components/Link'
import { helpHost, portalHost } from 'constants/env'

import DropdownForHeader from '../Dropdown'

import style from './style.less'

export default function Nav() {
  const productsOverlay = (
    <Menu>
      <Link href="/products/node">
        <MenuItem>NiuLink 大节点</MenuItem>
      </Link>
      <Link href="/products/lite">
        <MenuItem>麻雀云小盒子</MenuItem>
      </Link>
    </Menu>
  )

  return (
    <nav className={style.nav}>
      <ItemWithLink href="/">首页</ItemWithLink>
      <ItemWithDropdown overlay={productsOverlay}>软件产品</ItemWithDropdown>
      <ItemWithLink href="/cooperations">商务合作</ItemWithLink>
      <ItemWithLink href={helpHost}>帮助中心</ItemWithLink>
      <ItemWithLink href={portalHost}>控制台</ItemWithLink>
      <ItemWithLink href={`${portalHost}/sign-in`}>登录</ItemWithLink>
      <Link href={`${portalHost}/sign-up`} className={style.signup}>立即注册</Link>
    </nav>
  )
}

type ItemWithOverlayProps = PropsWithChildren<{
  visible?: boolean
  overlay: ReactElement
  overlayOffsetX?: number
}>

export function ItemWithOverlay({ visible, overlay, overlayOffsetX, children }: ItemWithOverlayProps) {
  const offsetX = overlayOffsetX != null ? overlayOffsetX : 0
  const ref = useRef(null)
  const [active, setActive] = useState(visible || false)
  return (
    <div ref={ref} className={style.item}>
      <DropdownForHeader
        visible={visible}
        align={{ offset: [offsetX, -1] }}
        overlay={overlay}
        overlayClassName={style.overlay}
        onVisibleChange={setActive}
      >
        <a className={classnames(style.itemText, active && 'active')}>{children}</a>
      </DropdownForHeader>
    </div>
  )
}

type ItemLinkProps = PropsWithChildren<{
  href: string
}>

export function ItemWithLink({ href, children }: ItemLinkProps) {
  const linkClassName = classnames(style.itemText, style.link)
  return (
    <div className={style.item}>
      <Link href={href} className={linkClassName}>{children}</Link>
    </div>
  )
}

type ItemWithDropdownProps = PropsWithChildren<{
  overlay: ReactElement
}>

export function ItemWithDropdown({ overlay, children }: ItemWithDropdownProps) {
  const linkClassName = classnames(style.itemText, style.link)
  return (
    <div className={style.item}>
      <Dropdown overlay={overlay} overlayStyle={{ zIndex: 9999 }} align={{ offset: [-20, -10] }}>
        <Link className={linkClassName}>{children}</Link>
      </Dropdown>
    </div>
  )
}
