/**
 * @file 右侧的回到顶部按钮及二维码引导
 */

import React from 'react'
import cn from 'classnames'

import { useMobile } from 'hooks/ua'
import ScrollToTop from 'components/ScrollToTop'
import Link from 'components/Link'

import node from 'images/mp-node.jpg'
import lite from 'images/mp-lite.png'

import ContactIcon from './images/contact-icon.svg'
import WechatIcon from './images/wechat-icon.svg'

import style from './style.less'

export default function PcPin() {
  const isMobile = useMobile()

  if (isMobile) return null

  return (
    <div className={style.wrapper}>
      <Link className={cn(style.btn, style.linkBtn)} href="/cooperations#apply">
        <ContactIcon />
      </Link>
      <button className={style.btn} type="button">
        <WechatIcon />
        <div className={cn(style.intro, style.mp)}>
          <img src={node} />
          <div className={style.tip}>NiuLink 小程序</div>
          <img src={lite} style={{ marginTop: '16px' }} />
          <div className={style.tip}>麻雀云小程序</div>
        </div>
      </button>
      <ScrollToTop />
    </div>
  )
}
