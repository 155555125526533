/**
 * @file 配置的环境变量，具体值见 .env & .env.<enviroment> 文件
 * @description 相关文档 https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables
 */

export const isDev = process.env.NODE_ENV === 'development'

/** 站点 Host */
export const host = must('host', process.env.NEXT_PUBLIC_HOST)

/** 帮助中心站点 Host */
export const helpHost = must('host', process.env.NEXT_PUBLIC_HELP_HOST)

export const portalHost = must('portalHost', process.env.NEXT_PUBLIC_PORTAL_HOST)

export const apiHost = portalHost

/** 构建时间（时间戳），注意该值不来自 .env.* 文件，而是在 next.config.js 中配置（从而动态获得当前时间） */
export const builtAt = parseInt(must('builtAt', process.env.NEXT_PUBLIC_BUILT_AT), 10)

function must(name: string, variable?: string): string {
  if (variable == null) {
    throw new Error(`Invalid value for environment variable ${name}, you need to configure it in env file`)
  }
  return variable
}
