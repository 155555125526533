/**
 * @file UA 信息 Provider
 * @description UA 信息包括是否移动、浏览器、操作系统等
 */

import React, { PropsWithChildren, useState, useEffect } from 'react'
import { UAParser } from 'ua-parser-js'
import { UaContext, useUa, Ua } from 'hooks/ua'

export default function UaProvider({ children }: PropsWithChildren<{}>) {
  // 满足某些场景需要手动提供 ua 的情况，可以在父组件 provider 覆盖手动值
  const ua = useUa()
  const isMobile = useIsMobile()
  const isPcLg = useIsPcLg()
  const isWx = useIsWx()
  const loaded = useLoaded()
  const uaParser = useUaParser()

  const uaValue: Ua = {
    isPcLg,
    isMobile,
    isWx,
    loaded,
    browser: uaParser.getBrowser(),
    os: uaParser.getOS(),
    ...ua
  }

  return (
    <UaContext.Provider value={uaValue}>
      {children}
    </UaContext.Provider>
  )
}

// 默认 UA 值，用于静态渲染 & 首屏初始化逻辑
const defaultUaText = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/83.0.4103.97 Safari/537.36'

function useUaParser() {
  const [parser, setParser] = useState<UAParser>(new UAParser(defaultUaText))
  useEffect(() => {
    setParser(new UAParser())
  }, [])
  return parser
}

enum Screen {
  Mobile = 'mobile',
  PcNormal = 'pc-normal',
  PcLarger = 'pc-larger',
}

// 同 utils/less/index.less 关于 screen 尺寸的定义
const screenSizeMap = {
  [Screen.Mobile]: 768,
  [Screen.PcNormal]: 1280,
  [Screen.PcLarger]: 1600
}

function useIsPcLg() {
  // 初始状态使用 false，以保持客户端渲染跟静态（服务端）渲染逻辑的一致
  const [isPcLg, setIsPcLg] = useState(false)
  useEffect(() => {
    function syncIsPcLg() {
      setIsPcLg(getIsPcLg())
    }
    syncIsPcLg()
    window.addEventListener('resize', syncIsPcLg)
    return () => window.removeEventListener('resize', syncIsPcLg)
  }, [])

  return isPcLg
}

export function getIsPcLg() {
  return (
    typeof window !== 'undefined'
    // 同 utils/style.less `.mobile()` 实现
    && window.matchMedia(`(min-width: ${screenSizeMap[Screen.PcLarger]}px)`).matches
  )
}

function useIsMobile() {
  // 初始状态使用 false，以保持客户端渲染跟静态（服务端）渲染逻辑的一致
  return useIsMobileWithInitialState(false)
}

export function useIsMobileWithInitialState(initialState: boolean) {
  const [isMobile, setIsMobile] = useState(initialState)

  useEffect(() => {
    function syncIsMobile() {
      setIsMobile(getIsMobile())
    }
    syncIsMobile()
    window.addEventListener('resize', syncIsMobile)
    return () => window.removeEventListener('resize', syncIsMobile)
  }, [])

  return isMobile
}

export function getIsMobile() {
  return (
    typeof window !== 'undefined'
    // 同 utils/less/index.less `.mobile()` 实现
    && window.matchMedia(`(max-width: ${screenSizeMap[Screen.Mobile] - 1}px)`).matches
  )
}

function useLoaded() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (window.document.readyState === 'complete') {
      setLoaded(true)
      return
    }

    const markLoaded = () => setLoaded(true)
    window.addEventListener('load', markLoaded)
    return () => window.removeEventListener('load', markLoaded)
  }, [])

  return loaded
}

function useIsWx() {
  // 是否是微信环境
  const uaParser = useUaParser()
  const [isWx, setIsWx] = useState(false)

  useEffect(() => {
    if (uaParser.getBrowser().name === 'WeChat') {
      setIsWx(true)
    }
  }, [uaParser])

  return isWx
}

