import React from 'react'
import Link from 'next/link'

import Nav from './Nav'

import Logo from './logo.svg'
import style from './style.less'

export default function HeaderForMobile() {
  return (
    <div className={style.header}>
      <Link href="/"><a className={style.logo}><Logo /></a></Link>
      <div className={style.spacer} />
      <Nav />
    </div>
  )
}
