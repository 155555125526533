import React, { CSSProperties } from 'react'
import { Popover } from 'react-icecream-2'

import wxLite from 'images/wx-lite.png'
import official from 'images/official.jpg'
import wxNode from 'images/wx-node.png'
import douyin from 'images/douyin.png'
import shipinhao from 'images/shipinhao.png'

import styles from './style.less'

export default function Contact() {
  return (
    <div className={styles.contact}>
      <ContactItem zoomable text="NiuLink 官方社群" icon={wxNode} />
      <ContactItem zoomable text="麻雀云官方社群" icon={wxLite} />
      <ContactItem zoomable text="NiuLink 公众号" icon={official} />
      <ContactItem zoomable text="NiuLink 抖音号" icon={douyin} />
      <ContactItem zoomable text="微信视频号" icon={shipinhao} />
    </div>
  )
}

type ContactItemProps = {
  icon: string
  text: string
  style?: CSSProperties
  zoomable?: boolean
}

export function ContactItem({ icon, text, style, zoomable = false }: ContactItemProps) {
  const iconContent = (
    <div className={styles.iconWrap}>
      <img src={icon} />
    </div>
  )

  const iconPopover = (
    <Popover
      trigger="hover"
      placement="top"
      overlayClassName={styles.contactPopover}
      content={<div className={styles.popoverWrap}><img src={icon} /></div>}
    >
      {iconContent}
    </Popover>
  )

  return (
    <div className={styles.contactItem} style={style}>
      {zoomable ? iconPopover : iconContent}
      <div className={styles.label}>{text}</div>
    </div>
  )
}
